import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      selectedIndex: 0,
      tabs: []
    };
  },
  created: function created() {
    this.tabs = this.$children;
  },
  mounted: function mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab: function selectTab(i) {
      this.selectedIndex = i;
      this.tabs.forEach(function (tab, index) {
        tab.isActive = index === i;
      });
    }
  }
};