import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    styles: {
      type: [Array, Object, String],
      default: ''
    }
  },
  computed: {
    open: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    closeModal: function closeModal() {
      this.open = !this.open;
    }
  }
};