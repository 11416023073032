import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.min-safe-integer.js";
import "core-js/modules/es.number.max-safe-integer.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'VueNumberInput',
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      firstDeltaY: undefined,
      currentDeltaY: undefined,
      timeoutId: undefined,
      intervalId: undefined
    };
  },
  computed: {
    inputValue: {
      get: function get() {
        return this.value.toString().replace('.', ',');
      },
      set: function set(value) {
        value = parseFloat(value.replace(',', '.')).toFixed(2);

        if (value < this.min) {
          value = this.min;
        }

        if (value > this.max) {
          value = this.max;
        }

        this.$emit('input', value);
      }
    }
  },
  methods: {
    buttonDownHandler: function buttonDownHandler(direction) {
      var value = parseFloat(this.value);
      this.makeStep(direction === 'inc' ? value + this.step : value - this.step);
    },
    makeStep: function makeStep(val) {
      if (val >= this.min && val <= this.max && !this.disabled) {
        this.$emit('input', val.toFixed(2));
      }

      if (isNaN(val)) {
        this.$emit('input', this.min.toFixed(2));
      }
    }
  }
};