import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
export default {
  provide: function provide() {
    return {
      Accordion: this.Accordion
    };
  },
  props: {},
  data: function data() {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    };
  }
};