import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';
export default {
  props: {
    cards: {
      type: Array,
      default: function _default() {
        return [];
      },
      required: true
    }
  },
  data: function data() {
    return {
      swiper: null
    };
  },
  mounted: function mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true
      }
    });
  }
};