import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import product from '~/graphql/queries/product.gql';
import randomProducts from '~/graphql/queries/randomProducts.gql';
export default {
  apollo: {
    product: {
      query: product,
      variables: function variables() {
        return {
          slug: this.$route.params.slug
        };
      },
      result: function result(_ref) {
        var product = _ref.data.product;

        if (!product) {
          this.$nuxt.error({
            statusCode: 404,
            message: 'Product not found'
          });
        }

        if (product.type_waarde === 'Vast') {
          this.form.value = product.waarde;
        } else {
          this.form.value = parseFloat(product.waarde_min).toFixed(2);
        }
      }
    },
    randomProducts: {
      query: randomProducts,
      variables: {
        limit: 3
      },
      result: function result(_ref2) {
        var data = _ref2.data;

        if (data.randomProducts) {
          this.randomProducts = this.mapRandomProducts(data.randomProducts);
        }
      },
      skip: function skip() {
        return this.whiteLabelEnabled;
      }
    }
  },
  data: function data() {
    return {
      relatedProducten: [],
      cartObject: {},
      product: {},
      randomProducts: [],
      form: {
        value: 0,
        quantity: 1,
        shippingMethod: 'email',
        businessOrder: false
      },
      openModal: false,
      maxQuantity: 100,
      priceInterval: 0
    };
  },
  nuxtI18n: {
    paths: {
      nl: '/assortiment/:slug',
      en: '/assortment/:slug'
    }
  },
  head: function head() {
    var _this$selectedProduct, _this$selectedProduct2;

    return {
      title: (_this$selectedProduct = (_this$selectedProduct2 = this.selectedProduct) === null || _this$selectedProduct2 === void 0 ? void 0 : _this$selectedProduct2.title) !== null && _this$selectedProduct !== void 0 ? _this$selectedProduct : ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    whiteLabelEnabled: 'whitelabel/isEnabled'
  })), {}, {
    selectedProduct: function selectedProduct() {
      if (this.form.shippingMethod === 'email') {
        return _objectSpread(_objectSpread({}, this.product), this.product.digital_sibling);
      }

      return this.product;
    },
    shippingOptions: function shippingOptions() {
      var options = [];

      if (this.product.fysiek) {
        options.push({
          id: 2,
          value: 'post',
          label: this.$t('By post')
        });
      }

      if (!this.product.fysiek || this.product.digital_sibling) {
        options.push({
          id: 1,
          value: 'email',
          label: this.$t('By email')
        });
      }

      return options;
    },
    price: function price() {
      return this.isVast ? this.product.waarde : this.product.waarde_max;
    },
    isVast: function isVast() {
      return this.selectedProduct.type_waarde === 'Vast';
    },
    quantityRange: function quantityRange() {
      return Array.from({
        length: this.maxQuantity
      }, function (v, k) {
        return k + 1;
      });
    },
    priceOptions: function priceOptions() {
      if (this.selectedProduct.tegoed_opties) {
        return this.selectedProduct.tegoed_opties.split(',');
      } else {
        return [];
      }
    },
    productHasImage: function productHasImage() {
      var _this$product$active_;

      return (_this$product$active_ = this.product.active_image) === null || _this$product$active_ === void 0 ? void 0 : _this$product$active_.url;
    }
  }),
  methods: {
    addToCart: function addToCart() {
      if (this.form.businessOrder) {
        this.$store.commit('cart/setOrderAs', 'business');
      }

      this.cartObject = {
        product: this.selectedProduct,
        quantity: +this.form.quantity,
        amount: this.form.value
      };
      this.$store.commit('cart/add', this.cartObject);
      return this.openModal = true;
    },
    mapRandomProducts: function mapRandomProducts(products) {
      var _this = this;

      return products.map(function (product) {
        return {
          id: product.id,
          slug: product.slug,
          title: product.title,
          fysiek: product.fysiek,
          own_design: product.own_design,
          content: product.type_waarde === 'Min/Max' ? _this.$options.filters.money(product.waarde_min) + ' - ' + _this.$options.filters.money(product.waarde_max) : _this.$options.filters.money(product.waarde),
          img: {
            source: product.active_image ? product.active_image.url : ''
          },
          styleObject: {
            backgroundColor: product.color_code || '#fff'
          }
        };
      });
    }
  }
};