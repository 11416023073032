import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  inject: ['Accordion'],
  props: {},
  data: function data() {
    return {
      index: null
    };
  },
  computed: {
    visible: function visible() {
      return this.index === this.Accordion.active;
    }
  },
  created: function created() {
    this.index = this.Accordion.count++;
  },
  methods: {
    open: function open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start: function start(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    end: function end(el) {
      el.style.height = '';
    }
  }
};