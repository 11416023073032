import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.promise.finally.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    detailPage: {
      type: Boolean,
      default: false
    },
    input: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    productHasImage: function productHasImage() {
      var _this$product$active_;

      return (_this$product$active_ = this.product.active_image) === null || _this$product$active_ === void 0 ? void 0 : _this$product$active_.url;
    },
    modalOpen: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  }
};